<template>
  <v-row>
    <v-col cols="12" lg="4">
      <images-upload
        v-model="obSettings"
        cols="12"
        hide-images
        lg="12"
        preview-label="login.image"
      />
    </v-col>
    <v-col cols="12" lg="8">
      <v-row>
        <v-col cols="12" lg="4">
          <form-field-text
            v-model="currencyUI"
            :hint="$t('currency.ui.description')"
            input-type="number"
            label="currency.ui"
            persistent-hint
          />
        </v-col>

        <v-col cols="12" lg="4">
          <form-field-text
            v-model="maxGlobalDiscounts"
            :hint="$t('max.global.discounts.description')"
            input-type="number"
            label="max.global.discounts"
            persistent-hint
          />
        </v-col>

        <v-col cols="12" lg="4">
          <form-field-text
            v-model="priceListLabel"
            :hint="$t('pricelist.label.default.description')"
            label="pricelist.label.default"
            persistent-hint
          />
        </v-col>

        <v-col cols="12" lg="6">
          <form-field-label label="invoice.ui" />
          <template v-for="(obInvoiceUI, index) in maxInvoiceUi">
            <max-invoice-ui
              :key="`invoice.ui.${index}`"
              :max="getMaxDate(index + 1)"
              :min="getMinDate(index - 1)"
              :value="obInvoiceUI"
              @input="onUpdateUI($event, index)"
            />
          </template>
          <form-field-hint label="invoice.ui.description" />
        </v-col>

        <v-col cols="12" lg="6">
          <v-switch
            v-model="showCompanyBalance"
            :label="$t('show.company.balance')"
          />
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12">
      <v-subheader v-text="$t('settings.company')" />
      <v-row>
        <companies-picker v-model="companyId" singleOption />
      </v-row>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Settings } from "@planetadeleste/vue-mc-gw";
import { Component, VModel, Vue } from "vue-property-decorator";
import CompaniesPicker from "@/modules/companies/components/CompaniesPicker.vue";
import MaxInvoiceUi, {
  MaxInvoicesUI,
} from "@/modules/settings/components/MaxInvoiceUI.vue";
import dayjs from "dayjs";
import { castArray } from "lodash";

@Component({
  components: {
    MaxInvoiceUi,
    CompaniesPicker,
  },
})
export default class SettingsesSettings extends Vue {
  @VModel({ type: Object, default: () => new Settings() })
  obSettings!: Settings;

  get currencyUI() {
    return this.obSettings && this.obSettings instanceof Settings
      ? this.obSettings.get("currency_ui", 0)
      : 0;
  }

  set currencyUI(fValue: number) {
    if (!this.obSettings || !(this.obSettings instanceof Settings)) {
      return;
    }

    this.obSettings.set("currency_ui", fValue);
  }

  get priceListLabel() {
    return this.obSettings && this.obSettings instanceof Settings
      ? this.obSettings.get("pricelist_label")
      : null;
  }

  set priceListLabel(sValue: string) {
    this.obSettings.set("pricelist_label", sValue);
  }

  get maxGlobalDiscounts(): number {
    return this.obSettings && this.obSettings instanceof Settings
      ? this.obSettings.get("max_discounts", 0)
      : 0;
  }

  set maxGlobalDiscounts(fValue: number) {
    this.obSettings.set("max_discounts", fValue);
  }

  get maxInvoiceUi(): MaxInvoicesUI[] {
    const arDefault = [{ date: "", value: 0 }];
    const arValue =
      this.obSettings && this.obSettings instanceof Settings
        ? this.obSettings.get("invoice_ui", arDefault)
        : arDefault;

    return this.$_.isArray(arValue) ? arValue : arDefault;
  }

  set maxInvoiceUi(sValue: MaxInvoicesUI[]) {
    this.obSettings.set("invoice_ui", sValue);
  }

  get showCompanyBalance(): boolean {
    return this.obSettings.get("show_company_balance", false);
  }

  set showCompanyBalance(sValue: boolean) {
    this.obSettings.set("show_company_balance", sValue);
  }

  get companyId(): number[] {
    return castArray(this.obSettings.get("company_id", []));
  }

  set companyId(sValue: number[]) {
    this.obSettings.set("company_id", sValue);
  }

  onUpdateUI(sValue: MaxInvoicesUI, index?: number) {
    if (this.$_.isEmpty(sValue)) {
      return;
    }

    const arInvoicesUI = this.$_.filter(this.maxInvoiceUi, (obItem) => {
      return !!obItem.date && obItem.value >= 0;
    });

    if (this.$_.isUndefined(index) || index < 0) {
      arInvoicesUI.push(sValue);
    } else {
      arInvoicesUI.splice(index, 1, sValue);
    }

    arInvoicesUI.push({ date: "", value: 0 });
    this.maxInvoiceUi = arInvoicesUI;
  }

  getMinDate(index: number): string | undefined {
    if (index < 0) {
      return undefined;
    }
    const sValue = this.$_.get(this.maxInvoiceUi, `${index}.date`);
    return sValue ? dayjs(sValue).add(1, "day").format() : undefined;
  }

  getMaxDate(index: number): string | undefined {
    if (index == this.maxInvoiceUi.length) {
      return undefined;
    }
    const sValue = this.$_.get(this.maxInvoiceUi, `${index}.date`);
    return sValue ? dayjs(sValue).subtract(1, "day").format() : undefined;
  }
}
</script>
